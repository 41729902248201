<template>
  <section>
    <section v-if="columnType == 'string'">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-bind="{ ...$props, ...$attrs }"
            v-model="val"
            color="indigo darken-4"
            :label="fieldLabel"
            :placeholder="field.placeholder || ''"
            @change="changeEvent"
          ></v-text-field>
        </v-col>
      </v-row>
    </section>
    <section v-else-if="columnType == 'text'">
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-bind="{ ...$props, ...$attrs }"
            v-model="val"
            color="indigo darken-4"
            :label="fieldLabel"
            :placeholder="field.placeholder || ''"
            @change="changeEvent"
          ></v-textarea>
        </v-col>
      </v-row>
    </section>
    <section v-else-if="columnType == 'number'">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-bind="{ ...$props, ...$attrs }"
            v-model="val"
            color="indigo darken-4"
            :label="fieldLabel"
            :placeholder="field.placeholder || ''"
            @change="changeEvent"
          ></v-text-field>
        </v-col>
      </v-row>
    </section>
    <section v-else-if="columnType === 'selectWithQuery'">
      <v-row align="center">
        <v-col class="d-flex flex-column" cols="12">
          <label>{{ fieldLabel }}</label>
          <multiselect
            id="ajax"
            class="customMultiSelect"
            v-model="searchVal"
            :options="options"
            :searchable="true"
            :internal-search="false"
            :clear-on-select="false"
            :show-no-results="false"
            :hide-selected="true"
            placeholder="Pick a value"
            @input="updateVal"
            @search-change="findAsync"
          >
            <template slot="singleLabel" slot-scope="{ option }">{{
              option.label
            }}</template>
            <template slot="option" slot-scope="props">
              {{ props.option.label }}
            </template>
          </multiselect>
        </v-col>
      </v-row>
    </section>
    <section
      v-else-if="
        columnType == 'select' ||
        columnType == COLUMN_TYPE.selectWithCustomQuery ||
        columnType == COLUMN_TYPE.selectWithStaticValues
      "
    >
      <v-row align="center">
        <v-col class="d-flex flex-column" cols="12">
          <v-select
            light
            color="indigo darken-4"
            item-color="indigo darken-4"
            :items="options"
            item-text="label"
            item-value="value"
            :label="fieldLabel"
            return-value
            @input="updateVal"
          ></v-select>
        </v-col>
      </v-row>
    </section>
    <section v-else-if="columnType == 'boolean'">
      <v-row align="center">
        <v-col class="d-flex" cols="12">
          <v-select
            light
            color="indigo darken-4"
            item-color="indigo darken-4"
            :items="[true, false]"
            :label="fieldLabel"
            @input="updateVal"
          ></v-select>
        </v-col>
      </v-row>
    </section>
    <section v-else-if="columnType == 'date'">
      <!-- @selectType = this is to describe what to of selection u want like in range selector where start and end is selected or simple date where only one field is required -->
      <!-- @type = this describes what type of return alue is needed like you want the values to be in datetime or only date -->
      <Date
        :label="`${field.label}`"
        v-model="val"
        @change="updateVal"
        selectType="date"
        :type="columnType"
        v-bind="{ ...$props, ...$attrs }"
      />
    </section>
    <section v-else-if="columnType == 'custom_date'">
      <!-- @selectType = this is to describe what to of selection u want like in range selector where start and end is selected or simple date where only one field is required -->
      <!-- @type = this describes what type of return alue is needed like you want the values to be in datetime or only date -->
      <Date
        :label="`${field.label}`"
        v-model="val"
        @change="updateVal"
        selectType="custom_date"
        :type="columnType"
        v-bind="{ ...$props, ...$attrs }"
      />
    </section>
    <section v-else-if="columnType == 'datetime'">
      <!-- @selectType = this is to describe what to of selection u want like in range selector where start and end is selected or simple date where only one field is required -->
      <!-- @type = this describes what type of return alue is needed like you want the values to be in datetime or only date -->
      <DateTime
        :label="`${field.label}`"
        v-model="val"
        @change="updateVal"
        selectType="date"
        :type="columnType"
        v-bind="{ ...$props, ...$attrs }"
      />
    </section>
    <section v-else-if="columnType == 'dateRange'">
      <DateRange
        :label="`${field.label}`"
        v-model="dateRange"
        @change="updateVal"
        type="date"
        v-bind="{ ...$props, ...$attrs }"
      />
    </section>
    <section v-else-if="columnType == 'dateTimeRange'">
      <DateTimeRange
        :label="`${field.label}`"
        v-model="dateTimeRange"
        @change="updateVal"
        type="datetime"
        v-bind="{ ...$props, ...$attrs }"
      />
    </section>
    <section v-else-if="columnType == 'integerRange'">
      <IntegerRange
        :label="`${field.label}`"
        v-model="val"
        @change="updateVal"
        :placeholder="field.placeholder || ''"
        type="integerRange"
        v-bind="{ ...$props, ...$attrs }"
      />
    </section>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import _ from "lodash";
import { COLUMN_TYPE } from "../../../core/services/consts";
export default {
  model: {
    event: "change",
  },
  props: {
    action: {
      type: String,
    },
    field: {
      type: Object,
      required: true,
    },
    value: {
      default: "",
    },
  },
  data() {
    return {
      COLUMN_TYPE,
      findAsync: null,
      val: "",
      searchVal: "",
      options: [],
      dateRange: {},
      dateTimeRange: {},
      dateTime: {},
      date: {},
      $inputObject: {},
    };
  },
  computed: {
    fieldLabel() {
      let label = this.field.label;
      switch (this.action) {
        case "filter":
          label = this.field.filter?.label || this.field.label;
      }
      return label;
    },

    columnType() {
      let columnType = this.field.columnType;
      switch (this.action) {
        case "filter":
          columnType = this.field.filter?.columnType || this.field.columnType;
      }
      return columnType;
    },
  },
  components: {
    IntegerRange: () => import("./IntegerRange.vue"),
    DateTimeRange: () => import("./DateTimeRange.vue"),
    DateTime: () => import("./DateTimeRange.vue"),
    Date: () => import("./DateTimeRange.vue"),
    DateRange: () => import("./DateRange.vue"),
    Multiselect,
  },
  methods: {
    updateVal(payload) {
      this.val =
        payload && typeof payload === "object" ? payload.value : payload;
      this.changeEvent();
    },

    changeEvent() {
      this.$emit("change", this.val);
    },

    asyncFind(searchInput = "") {
      this.getDropdownOptions(this.field, this.action, searchInput);
    },

    getDropdownOptions(selectedField, action = null, input = "") {
      let params = null;

      switch (action) {
        case "filter":
          params = _.cloneDeep(selectedField.filter.dropdown);
          params.selectedFilterInput = selectedField.filter.selectedFilterInput;
          break;
        default:
          params = _.cloneDeep(selectedField.options);
      }
      params.userInput = input;
      this.getInputRecords(params)
        .then((response) => {
          const options = (response?.data?.records || []).map((option) => {
            const optionLabel = params.label
              .map((field) => {
                return _.get(option, field);
              })
              .join(params?.labelSeperator || "");

            const optionValue = _.get(option, params.value);

            return {
              label: optionLabel,
              value: optionValue,
            };
          });
          this.options = [{ value: null, label: "Select" }, ...options];
          this.searchVal = this.options.filter(
            (record) => record.value === this.value
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },

    ...mapActions(["getInputRecords"]),
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.val = this.value;
      },
    },
  },
  created() {
    if (
      this.field.filter?.dropdown?.fetchQuery &&
      this.field.filter.columnType === "selectWithQuery"
    ) {
      this.findAsync = _.debounce(this.asyncFind.bind(this), 600);
    }
  },
  mounted() {
    switch (this.columnType) {
      case "select":
      case "selectWithQuery":
        this.getDropdownOptions(this.field, this.action);
        break;
      case this.COLUMN_TYPE.selectWithCustomQuery:
      case this.COLUMN_TYPE.selectWithStaticValues: {
        const options = _.cloneDeep(this.field.filter?.dropdown?.items || []);
        this.options = options.map((opt) => {
          if (typeof opt === "object") return opt;

          return {
            label: opt,
            value: opt,
          };
        });
        break;
      }
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.v-application ul,
.v-application ol {
  padding-left: 0;
}
.multiselect__option--highlight {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
.customMultiSelect {
  .multiselect__tags {
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-color: rgba(0, 0, 0, 0.42);
    border-radius: 0;
  }
  .multiselect__option--highlight {
    color: #1867c0 !important;
    caret-color: #1867c0 !important;
    background-color: rgba(0, 0, 0, 0.05);
    &::after {
      background-color: rgba(0, 0, 0, 0.05);
      color: #1867c0 !important;
      caret-color: #1867c0 !important;
    }
  }
  .multiselect__content-wrapper {
    box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
    border: 0;
  }
}
</style>
